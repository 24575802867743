<template>
  <div class="Projects position-relative animation" style="animation-delay: 2s;">
    <div
      class="same-padding-appbar update-padding"
      v-bind:class="{
        'absolute-filters-btns': view_big,
        'position-relative': view_big == false,
        'absolute-filters-resolution' : view_squares
      }"
      v-bind:style="view_big == false ? 'z-index: 2;' : ''"
    >
      <span
        class="Projects__filter black--text d-inline-block mr-5"
        @click="open_sidebar = !open_sidebar"
        v-bind:class="{ 'active-menu-filter': open_sidebar }"
        >Filtrar proyectos</span
      >

      <span
        class="d-inline-block mr-3"
        @click="(view_big = false), (view_squares = true), (view_list = false)"
      >
        <v-img
          src="@/assets/icons/vista-2.svg"
          :class="{ 'opacity-in-views': view_squares }"
        ></v-img>
      </span>

      <span
        class="d-inline-block mr-3"
        @click="(view_big = true), (view_squares = false), (view_list = false)"
      >
        <v-img
          src="@/assets/icons/vista-1.svg"
          :class="{ 'opacity-in-views': view_big }"
        ></v-img>
      </span>

      <span
        class="d-inline-block"
        @click="(view_big = false), (view_squares = false), (view_list = true)"
      >
        <v-img
          src="@/assets/icons/vista-3.svg"
          :class="{ 'opacity-in-views': view_list }"
        ></v-img>
      </span>
    </div>

    <!-- *********inicia sidebar********* -->
    <sideBar
      :open="open_sidebar"
      :changeContent="1"
      :projects="projectsSideBar"
      @close="open_sidebar = false"
      @rangeUpdating="rangeUpdated"
      @updatingFilters="filtersUpdated"
      @updatingCategories="updatedCategories"
    ></sideBar>
    <!-- *********/termina sidebar********* -->

    <!-- *********inicia view 1********* -->
    <viewA :show="view_big" :projectsC="getAllProjects"></viewA>
    <!-- *********/termina view 1********* -->

    <!-- *********inicia view 2********* -->
    <viewB :show="view_squares" :projectsC="getAllProjects"></viewB>
    <!-- *********/termina view 2********* -->

    <!-- *********inicia view 3********* -->
    <viewC :show="view_list" :projectsC="getAllProjects"></viewC>
    <!-- *********/termina view 3********* -->
  </div>
</template>

<script>
import viewA from "../components/Statics/ViewA";
import viewB from "../components/Statics/ViewB";
import viewC from "../components/Statics/ViewC";
import sideBar from "../components/Globals/SideBar";

export default {
  components: {
    viewA,
    viewB,
    viewC,
    sideBar,
  },
  computed: {
    projectsSideBar: function(){
      var projects = this.projects;

      return projects;
    },
    getAllProjects: function () {
      var projects = this.projects;
      var projectsF = [];
      var aux = this;

      if (this.filterByCategory != "" || this.$route.params.filter) {
        for (let index = 0; index < projects.length; index++) {
          const element = projects[index];

          for (let index = 0; index < element.categories.length; index++) {
            const element2 = element.categories[index];
            if (element2.name == aux.filterByCategory || element2.name == aux.$route.params.filter) {
              projectsF.push(element);
              // console.log(element2.name);
            }
          }
        }
      } 
      else{
        projectsF = projects;
      }
      
      projectsF = projectsF.filter(
          (pro) =>
            parseInt(pro.meters) >= this.rangeProjects[0] &&
            parseInt(pro.meters) <= this.rangeProjects[1]
        );

      return projectsF;
    },
  },
  data() {
    return {
      projects: [],
      view_squares: true,
      view_big: false,
      view_list: false,
      open_sidebar: false,
      //filtrar por metros
      rangeProjects: [1100, 5602],
      //filtrar por categoría
      filterByCategory: "",
      categories: [],
    };
  },
  beforeCreate() {
    //fetch for related projects
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/Projects?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 2,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.projects = res.entries))
      .catch((error) => console.log(error.message));
  },
  mounted() {
    this.$emit("update", 1);

    if(this.$route.params.filter){
      this.open_sidebar = true;
    }
  },
  methods: {
    showCarouselImages: function (index) {
      this.carouselShow = true;
      this.carouselImagesProjects = index;
    },
    rangeUpdated: function (newValue) {
      //función obtenida del componente sidebar acutalizando el filtrado por metros
      this.rangeProjects = newValue;
    },
    filtersUpdated: function (newValue) {
      //función obtenida del componente sidebar acutalizando el filtrado por categoría
      this.filterByCategory = newValue;
      // console.log(newValue);
    },
    updatedCategories: function (newValues) {
      this.categories = newValues;
    },
  },
};
</script>