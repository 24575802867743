<template>
  <transition name="fade-transition">
    <v-container fluid class="py-0 position-relative" v-if="show">
      <v-row class="animation" style="animation-delay: 2s;">
        <v-col
          cols="12"
          md="3"
          class="same-padding-appbar pt-12"
          id="col-height"
        >
          <template v-for="(project, pr2) in projectsC">
            <v-row
              v-show="carouselProjects == pr2"
              v-bind:key="pr2"
              class="Project--row-height-mobile viewA-row line-height-18 pt-xl-12"
              no-gutters
            >
              <v-col class="mb-2 mt-5 mt-sm-0">
                <v-row no-gutters>
                  <v-col cols="6" md="12">
                    <v-card
                      tile
                      elevation="0"
                      :ripple="false"
                      :to="'/project/' + project.url"
                    >
                      <div
                        class="Project--paragraphs titles capitalize aeonik-bold color-black word-spaces"
                        v-html="project.name"
                      ></div>
                      <div>
                        <span class="capitalize titles aeonik-light">{{
                          project.location
                        }}</span>
                      </div>
                      <div>
                        <span class="titles aeonik-light"
                          >{{ numberWithCommas(project.meters) }}m<sup>2</sup></span
                        >
                      </div>
                    </v-card>
                  </v-col>

                  <v-col cols="6" md="12" class="pl-6 pl-sm-0">
                    <v-row no-gutters class="fill-height">
                      <!-- tablet -->
                      <v-col cols="12" class="d-none d-sm-block">
                        <ul
                          class="pl-0 categories-project list-style-none mt-md-10"
                        >
                          <li
                            v-for="(project, pr3) in projectsC"
                            v-bind:key="pr3"
                            v-show="carouselProjects == pr3"
                          >
                            <template
                              v-for="(category, catA) in project.categories"
                            >
                              <span
                                v-bind:key="catA"
                                class="d-md-block black--text"
                              >
                                {{ firstLetterUppercase(category.name) }}
                              </span>
                            </template>
                          </li>
                        </ul>
                      </v-col>
                      <!-- /tablet -->

                      <!-- ver siguiente proyecto/relacionados tablet vertical hacia abajo -->
                      <v-col
                        cols="12"
                        class="align-self-start align-self-sm-end d-md-none"
                      >
                        <ul class="pl-0 list-style-none mt-sm-2">
                          <li class="mb-2">
                            <span
                              class="black--text"
                              @click="carouselProjects++"
                              >Ver siguiente proyecto</span
                            >
                          </li>
                        </ul>

                        <!-- tablet -->
                        <span
                          v-bind:class="{
                            'color-gray': relatedMenu,
                            'black--text': relatedMenu == false,
                          }"
                          class="d-none d-sm-block"
                          @click="relatedMenuMobile = !relatedMenuMobile"
                          >Proyectos relacionados</span
                        >
                        <!-- /tablet -->
                      </v-col>
                      <!-- /ver siguiente proyecto/relacionados tablet vertical hacia abajo -->
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <!-- columna oculta de tablet para abajo-->
              <v-col
                cols="12"
                class="align-self-end d-none d-md-block"
                @mouseenter="relatedMenu = true"
              >
                <ul class="pl-0 list-style-none">
                  <li class="mb-2">
                    <span class="black--text" @click="carouselProjects++"
                      >Ver siguiente proyecto</span
                    >
                  </li>
                </ul>

                <span
                  v-bind:class="{
                    'color-gray': relatedMenu == false,
                    'black--text': relatedMenu,
                  }"
                  @click="relatedMenu = true"
                  >Proyectos relacionados</span
                >
              </v-col>
              <!-- columna oculta de tablet para abajo-->
            </v-row>
          </template>

          <!-- only mobile -->
          <v-row no-gutters class="d-sm-none">
            <!-- mobile -->
            <v-col cols="12">
              <ul class="pl-0 categories-project list-style-none mt-1 mt-md-10">
                <li
                  v-for="(project, pr3) in projectsC"
                  v-bind:key="pr3"
                  v-show="carouselProjects == pr3"
                >
                  <template v-for="(category, catA) in project.categories">
                    <span v-bind:key="catA" class="categories-underline d-md-block black--text mr-2">
                      {{ firstLetterUppercase(category.name) }}
                    </span>
                  </template>
                </li>
              </ul>

              <!-- mobile -->
              <span
                v-bind:class="{
                  'color-gray': relatedMenuMobile,
                  'color-black mb-4': relatedMenuMobile == false,
                }"
                class="d-block d-sm-none mt-4"
                @click="relatedMenuMobile = !relatedMenuMobile"
                >Proyectos relacionados</span
              >
              <!-- /mobile -->
            </v-col>
            <!-- /mobile -->
          </v-row>
          <!-- /only mobile -->
        </v-col>
        <v-col cols="12" md="9" class="pa-0 column-viewA position-relative">
          <!--  tablet-->
          <div v-if="relatedMenuMobile" class="related_div_projects d-md-none">
            <!-- tablet -->
            <v-row
              class="fill-height d-none d-sm-flex"
              align="center"
              no-gutters
            >
              <v-col cols="12" md="12">
                <v-row no-gutters justify="space-between">
                  <v-col
                    cols="3"
                    v-for="(project, pi) in relatedProjects"
                    v-bind:key="pi"
                    v-bind:class="{ 'pr-5': pi <= 2 }"
                  >
                    <template v-if="project.miniature != null && pi <= 3">
                      <v-img
                        class="related_div_projects__img-preview"
                        :class="{ 'filter-img': projectId == project._id }"
                        :src="
                          'https://www.ofisdesigncms.actstudio.xyz/' +
                          project.miniature.path
                        "
                        @mouseover="
                          projectId = project._id;
                          carouselSelectById(project._id);
                        "
                        @mouseleave="projectId = null"
                      ></v-img>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- /tablet -->

            <!-- mobile -->
            <v-row class="fill-height d-sm-none" align="center" no-gutters>
              <v-col cols="12" md="12">
                <v-row no-gutters justify="space-between">
                  <v-col
                    cols="6"
                    v-for="(project, pi) in relatedProjects"
                    v-bind:key="pi"
                    v-bind:class="{ 'pr-5': pi <= 1, 'pl-5': pi == 0 }"
                  >
                    <template v-if="project.miniature != null && pi <= 1">
                      <v-img
                        class="related_div_projects__img-preview"
                        :class="{ 'filter-img': projectId == project._id }"
                        :src="
                          'https://www.ofisdesigncms.actstudio.xyz/' +
                          project.miniature.path
                        "
                        @mouseover="
                          projectId = project._id;
                          carouselSelectById(project._id);
                        "
                        @mouseleave="projectId = null"
                      ></v-img>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- /mobile -->
          </div>
          <!-- tablet -->

          <div class="position-relative">
            <v-carousel
              v-model="carouselProjects"
              v-bind:class="{
                related_menu_activated: relatedMenu || relatedMenuMobile,
              }"
              v-bind:show-arrows="false"
              hide-delimiters
              class="carouselProjects toClick"
            >
              <v-carousel-item
                class="carouselProjects__item"
                v-for="(project, pr) in projectsC"
                v-bind:key="pr"
                v-bind:src="
                  'https://www.ofisdesigncms.actstudio.xyz/' +
                  project.cover.path
                "
                v-bind:to="'/project/' + project.url"
                v-bind:ripple="false"
                transition="fade-transition"
                reverse-transition="fade-transition"
                @mouseover="relatedMenu = false"
              >
                <!-- <div class="projects-categories">
                  <template v-for="(category, catA) in project.categories">
                    <span
                      class="Project--categories black--text capitalize aeonik-regular"
                      :key="catA"
                      >{{ category.name }}</span
                    >
                  </template>
                </div> -->
              </v-carousel-item>
            </v-carousel>

            <div
              class="transparent-activator-right white-space"
              @click="carouselProjects++"
            ></div>
          </div>
          <div
            class="transparent-activator"
            v-bind:class="{ 'd-none': relatedMenu }"
            @mouseover="relatedMenu = true"
          ></div>

          <!--  desk-->
          <div
            v-if="relatedMenu"
            class="related_div_projects d-none d-md-block"
          >
            <!--  -->
            <v-row class="fill-height" align="center" no-gutters>
              <v-col cols="12" md="12">
                <v-row no-gutters justify="space-between">
                  <v-col
                    cols="3"
                    v-for="(project, pi) in relatedProjects"
                    v-bind:key="pi"
                    v-bind:class="{ 'pr-5': pi <= 2 }"
                  >
                    <template v-if="project.miniature != null && pi <= 3">
                      <v-img
                        class="related_div_projects__img-preview"
                        :class="{ 'filter-img': projectId == project._id }"
                        :src="
                          'https://www.ofisdesigncms.actstudio.xyz/' +
                          project.miniature.path
                        "
                        @mouseover="
                          projectId = project._id;
                          carouselSelectById(project._id);
                        "
                        @mouseleave="projectId = null"
                      ></v-img>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <!-- desk -->
        </v-col>
      </v-row>
    </v-container>
  </transition>
</template>

<script>
export default {
  name: "ViewA",
  props: {
    show: { type: Boolean, required: true },
    projectsC: { type: Array, required: true },
  },
  computed: {
    relatedProjects: function () {
      var arrRelatedProjects = this.projectsC;
      var newArray = [];

      for (let index = 0; index < arrRelatedProjects.length; index++) {
        const element = arrRelatedProjects[index];
        newArray.push(element);
      }

      return arrRelatedProjects;
    },
  },
  data() {
    return {
      carouselProjects: 0,
      relatedMenu: false,
      relatedMenuMobile: false,
      projectId: null,
    };
  },
  methods: {
    firstLetterUppercase: function (text) {
      //global method
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    carouselSelectById: function (Id) {
      this.carouselProjects = this.projectsC.findIndex(
        (project) => project._id == Id
      );
    },
    numberWithCommas: function(x) {
      return (x || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>